/* 	AddrRelation-0020.oc
*/

.addrrelation-0020.opc-item-list .list-item-column {
    flex: 3;
}

.addrrelation-0020.opc-item-list .list-item-column:nth-child(1) {
    flex: 2;
}

.addrrelation-0020.opc-item-list .list-item-column:nth-child(2),
.addrrelation-0020.opc-item-list .list-item-column:nth-child(4) {
    flex: 1;
}