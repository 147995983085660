/*###############################################

@Title: Content
@Description:


###############################################*/
html, body {
	margin: 0;   
	padding: 0;
	max-width: 100%;
}
html {
	font-size: 10px; 
  max-width: 100%;
  overflow: hidden;
}
body { 
	font-size: 1.4rem;
  font-family: $_opc-base-font-family;
  font-weight: $_opc-base-font-weight;
  line-height: $_opc-base-line-height;
  -webkit-font-smoothing: antialiased;
	background: #efefef;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}  
body {
  overflow: hidden;
}


.content {
	
  background: #efefef;
  position: absolute;
  left: 0;
  top: 5rem;
  right: 0;
  height: calc(100% - 5rem);
  overflow-x: hidden;
  overflow-y: auto;
  
  padding: $_header-padding / 2;

  @include mq($min-width: map-get($breakpoints, tiny)) {
	top: 6rem;
	height: calc(100% - 6rem);	
  }

  @include mq($min-width: map-get($breakpoints, m)) {
    left: 3rem;
    padding: $_header-padding / 1.5;
  }
  
  @include mq($min-width: map-get($breakpoints, l)) {
    left: 27rem;
    padding: $_header-padding;
  }

	
} 
