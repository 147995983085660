.LagerBereich{
	background-color: #E7E7E7;
	padding: 8px;
	border: 1px solid #000000;
	margin-top:20px;
	font-weight: bold;
  font-size: 16px;
}
.SubLagerBereich{
	padding: 8px;
	border-left: 1px solid #000000;
	border-right: 1px solid #000000;
	border-bottom: 1px solid #000000;
}