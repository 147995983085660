/* Lade-Icon Asynchron*/

.map-loader-overlay{
	width:100%;
	height:100%;
	background:rgba(255,255,255,0.5);
	z-index:3;
	position:absolute;
	display:none;
	.map-loading-icon{
		font-size:8rem;
		position: relative;
		left: calc(50% - 4rem);
		top: calc(50% - 6rem);
		margin:3rem 0;
		opacity:0.5;
	}
}
.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    display: none;
}

.mapboxgl-popup {
    min-width: 10rem;
}
.mapboxgl-popup-close-button {
    padding: 0rem 0.6rem;
    font-size: 2.5rem;
	right: -0.2rem;
    top: -0.2rem;
}
.mapboxgl-popup-content{
	padding-top:1.5rem;
	padding-bottom:1.5rem;
	img{
		max-height:60px;
		max-width:150px;
	}
	div{
		height: fit-content;
	}
	.opc-button{
		margin: 0.5rem 0;
		width:100%;
	}
	font:none;
}
.mapboxgl-map{
	font: 1.4rem "Open Sans",sans-serif
}
.map-marker-legend{
	display:flex;
	font-size:$_opc-base-font-size;
	font-family:$_opc-base-font-family;
	font-weight:$_opc-base-font-weight;
	line-height:$_opc-base-line-height;
	>div{
		display:flex;
		margin-right:2.5rem;
		align-items: center;
		.marker{
			margin-right: 0.6rem;
		}
	}
	.marker {
		height: 32px;
		width: 26px;
		span{
			width: 25px;
			height: 25px;
		}
	}
}
input[type=range]{
	accent-color: $brand-color-1;
}

// Custom Marker
.marker span {
  	display:flex;
  	justify-content:center;
  	align-items:center;
  	box-sizing:border-box;
  	color:#fff;
  	background: $brand-color-1;
  	border:solid 2px;
  	border-radius: 0 70% 70%;
  	box-shadow:0 0 2px #000;
  	cursor: pointer;
  	transform: rotateZ(-135deg);
  	width: 30px;
	height: 30px;
	svg{
		margin:0;
		margin-left: 0.3rem;
	}
}

.marker {
	display: inline-block;
	height: 37px;
	width: 31px;
	margin: 0px 2px;
}

.marker b {transform: rotateZ(135deg)}

.mapboxgl-map{
	.mapboxgl-marker.marker-semitransparent{
		opacity:0.6;
	}
	.marker {
		width:0; 
		height:0;
		display: block;
		margin:0;
	}
	.marker span {
		
		transform-origin:0 0;
	}
}

select.mapstyleselector{
	position:absolute;
	height: 3.2rem;
	line-height: 3.2rem;
	z-index:1;
}
.mapboxgl-ctrl-top-right,select.mapstyleselector{
	opacity:0.7;
	&:hover{
		opacity:1;
	}
}

.routeplanner{
	>.opc-row{
		height:calc(100vh - 28.5rem);
		min-height:400px;
	}
	.map{
		height: 100%;
	}
	.list{
		height: 100%;
	}
	.drop{
		background:transparent;
		&.active{
			height:6rem;
			background:darken(#efefef,5%);
		}
		background:#efefef;
		transition: height 0.2s, background 0.2s, display 0.2s;
		display:none;
		height:1rem;
		border-bottom:none;
	}
}
.routeplanner-form{
	.form-field-element.start,.form-field-element.end{
		display:flex;
		>span{
			margin-right:1rem;
		}
	}
	.panel-beside .panel-body{
		padding-bottom: 0;
	}
}
.matrix .list-item>.list-item-column{
	display:flex;
	[data-op-style="context-menu"]{
		display:none;
	}
}


.draggable{
	cursor:move;
}