/* 	Dispo-0010.oc
*/
.dispo-0010.opc-item-list .list-item-column {
	flex:2;
	&:nth-child(2),
	&:nth-child(4),
	&:nth-child(5),
	&:nth-child(6){
		flex:1;
	}
}