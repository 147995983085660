// namespace vars
$mainnav-bg: #f7f7f7;
$mainnav-padding: 1rem;
$mainnav-customer-logo-height: 45px;

// mainnav layout styles
.mainnav-wrapper {
    position: absolute;
    left: 0;
    top: 6rem;
    bottom: 0;
    width: 27rem;
    z-index: 10;
    background: $mainnav-bg;
    display: none;
    box-shadow: 0 3px 3px #ccc;
    transform: translateX(-100%);
    overflow-y: auto;
    @include mq($min-width: map-get($breakpoints, m)) {
        display: block;
        transform: translateX(calc(-100% + 3rem));
        transition: transform 0.3s ease-in-out;
        &::before {
            content: '';
            background: rgba(#000, 0);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 3rem;
            z-index: 10;
            transition: all 0.02s ease-in-out 0.4s;
            cursor: pointer;
		}
        &:hover {
            transform: translateX(0);
            transition-delay: 0.2s;
            &::before {
                z-index: -1;
            }
        }
    }
    @include mq($min-width: map-get($breakpoints, l)) {
        display: block;
        transform: translateX(0);
        &::before {
            display: none;
        }
    }
    .mainnav-inner {
        @extend %mw-inner;
        padding-top: 1rem;
    }
    .mainnav-content {
        height: calc(100vh - 7rem - 1rem - #{$mainnav-customer-logo-height} - 40px);
    }
    .customer-logo {
		height: calc(#{$mainnav-customer-logo-height} + 1rem);
        position: relative;
        width: 100%;
        img {
            width: auto;
            max-height: $mainnav-customer-logo-height;
            margin-top: 1rem;
            max-width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 0 2rem;
        }
    }
    .client-name {
        padding: 10px 2rem;
        color: #5a5c3c;
        height: 40px;
		overflow: hidden;

		.client-info{
			float:right;
		}
    }
    .navigation-head {
        padding: 0 2rem 0rem 2rem;
    	margin-bottom: 0;
        small {
            font-size: 1.2rem;
            padding: 0.8rem 0 0.5rem;
            display: block;
            text-transform: uppercase;
            color: $brand-link-color;
            margin: 0;
        }
        h4 {
            padding: 0;
            margin: 0;
			font-weight: 400;
			position: relative;
			
        }
        .image img {
            position:absolute;
			top: -0.8rem;
			right: -0.8rem;
            max-height: 2.8rem;
            width: auto;
            margin-top: 0.5em;
        }
    }
    .opc-navigation {
        li.user-settings {
            display: none;
        }
    }
}