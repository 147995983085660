

#form-sales-analysis{
	.opc-form-field{
		input,select{
			width:100%;
		}
	}
}

.salesanalysis-0050 .graphs{
	padding-left:5em;
	max-width:950px;
	width:100%;
	img{
		width:100%;
	}
}

.alive-value{
	position:relative;

	progress{
		width:10rem;
		height:0.75rem;
		border: none;

		-webkit-appearance: none;
		  appearance: none;

		top:0.5rem;

		&::-webkit-progress-bar {
			background-color: #efefef;
		}

		&::-moz-progress-bar {
			background-color: $success-color;
		}
		&::-webkit-progress-value{
			background-color: $success-color;
		}

		&[data-op-style="warning"]{
			&::-moz-progress-bar {
				background-color: $error-color;
			}
			&::-webkit-progress-value{
				background-color: $error-color;
			}
		}
		
	}
	span.value{
		position:absolute;
		text-align:center;
		display:inline-block;
		width:10rem;
		line-height:2.5rem;
		font-size:1.2rem;
		top:-1.1rem;
		left:0;
	}
}

.rfm{
	position:relative;
	display:grid; 
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr; 
	grid-template-rows: 1fr 1fr 1fr 1fr 1fr;  
	grid-template-areas: 
		"rfm9 rfm9 rfm2 rfm2 rfm1" 
		"rfm8 rfm8 rfm2 rfm2 rfm1" 
		"rfm8 rfm8 rfm6 rfm3 rfm3" 
		"rfm10 rfm10 rfm7 rfm3 rfm3" 
		"rfm11 rfm11 rfm7 rfm5 rfm4"; 
	width:100%; 
	min-height:30em;
	padding-bottom:1em;
	padding-left:1em;
	grid-gap: 0.1em;

	&::before{
		font-size:0.75em;
		display:block;
		position:absolute;
		bottom:0;
		left:50%;
		content:attr(data-op-label-x);
	}
	&::after{
		font-size:0.75em;
		display:block;
		position:absolute;
		top: 50%;
		left:0.5em;
		line-height:1em;
		content:attr(data-op-label-y);
		transform:rotate(270deg);
		transform-origin:left;
	}

	> a, > span{
		display:block;
		padding:0.5em;

		&.rfm-1{	grid-area: rfm1;	}
		&.rfm-2{	grid-area: rfm2;	}
		&.rfm-3{	grid-area: rfm3;	}
		&.rfm-4{	grid-area: rfm4;	}
		&.rfm-5{	grid-area: rfm5;	}
		&.rfm-6{	grid-area: rfm6;	}
		&.rfm-7{	grid-area: rfm7;	}
		&.rfm-8{	grid-area: rfm8;	}
		&.rfm-9{	grid-area: rfm9;	}
		&.rfm-10{	grid-area: rfm10;	}
		&.rfm-11{	grid-area: rfm11;	}
		&.rfm-12{	grid-area: rfm12;	}

		@include mq($max-width: map-get($breakpoints, m)) {
			font-size:0.9em;

			span.count-umsatz,
			span.count-pc{
				display:none;
			}
		}
	}

	> a:hover{
		cursor:pointer;
		color:#333;
		opacity:0.75;
	}

	> span{
		opacity:0.4;
	}
}
