/* 	Fiacc-0010.oc
*/

.fiacc-0010-bonitaet.opc-item-list .list-item-column:nth-child(3) {
	&[data-op-type="error"] {
		font-weight:bold;
        color: $error-color;
    }
}

@include mq($max-width: map-get($breakpoints, m)) {
	.xs-hide {
		display: none !important;
	}

	.list-item-column:before {
		margin-left: 10px;
	}
}