/*###############################################

# Header

###############################################*/

// namespace vars
$_header-bg: $theme-color-1; // header background color
$_header-offcanvas-menu-trigger-color: #fff; // color of the hamburger menu icon
$_header-padding: 2rem; // top and bottom padding of the header
$_header-offcanvas-menu-margin: 1rem; // space between hamburger menu and brand
$_header-spacing: 4rem; // Spacing between the header sections eg. brand, search, actions
// display config
$always-show-offcanvas-menu-trigger: 'false'; // define if you want to show the hamburger menu just on mobile or always    
$show-offcanvas-menu-trigger: map-get($breakpoints, medium); // set breakpoint to define when the hamburger will shown
// header layout styles
.header-wrapper {
    width: 100%;
    background: $_header-bg;
	@include mq($min-width: map-get($breakpoints, m)) {
		background-image: url($tk-path + "/img/splashscreen_1_40.png");
		background-repeat: no-repeat;
		background-blend-mode: luminosity;
		background-position-x:right;
	}
	@include mq($max-width: map-get($breakpoints, m)) {
		background-image: radial-gradient(98.62% 95.12% at 1em 50%,lighten($theme-color-1,3%) 0,darken($theme-color-1,3%) 100%);
	}
    box-shadow: 0 0 3px #ccc;
    position: relative;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    @include mq($min-width: map-get($breakpoints, medium)) {}
    @include mq($min-width: map-get($breakpoints, large)) {}
    .header-inner {
        @extend %mw-inner;
        display: flex;
        align-items: center;
        padding: 0 $_header-padding / 2;
        min-height: 5rem;
        @include mq($min-width: map-get($breakpoints, m)) {
            padding: 0 $_header-padding / 1.5;
        }
        @include mq($min-width: map-get($breakpoints, l)) {
            padding: 0 $_header-padding;
        }
    }
    // offcanvas menu
    .offcanvas-menu-trigger {
        margin-right: $_header-offcanvas-menu-margin;
        border: none;
        padding: 0.5rem;
        background: none;
        opacity: 0.6;
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
        span {
            @include burger(25px, 2px, 5px, $_header-offcanvas-menu-trigger-color);
        }
        &.is-active {
            span {
                @include burger-to-cross;
            }
        }
        @if $always-show-offcanvas-menu-trigger !='true' {
            display: none;
            @include mq($max-width: #{$show-offcanvas-menu-trigger}) {
                display: block;
            }
        }
        order: 1;
    }
    // logo 
    .brand {
        order: 2;
        height: 6rem;
        //display: none;
        align-items: center;
        flex-shrink: 0;
        padding: 0;
        @include mq($min-width: map-get($breakpoints, m)) {
			padding-right:2rem;
        }
        img {
			display: block;
            max-height: 4.9rem;
            height: 4.9rem;
            max-width: 26rem;
            width: auto;
        }
		@include mq($min-width: map-get($breakpoints, tiny)) {
			display: flex;
			
		}
		@include mq($max-width: map-get($breakpoints, tiny)) {
			img {
				max-height: 4.2rem;
				height: 4.2rem;
				margin-top: 0.6em;
				margin-left: 0.2em;
			}
		}
    }
    // header actions
    .header-nav {
        display: flex;
        display: none;
        order: 3;
        height: 6rem;
        flex-grow: 1;
        margin-right: 1rem;
        margin-left: 1rem;
        @include mq($min-width: map-get($breakpoints, m)) {
            display: flex;
            overflow-x: auto;
            margin-left: 0;
            margin-right: 2rem;
        }
        ul.navigation-level-1 {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-shrink: 0;
            height: 100%;
            li {
				&.is-active {
					background: rgba(#000, 0.2) !important;

					> a{
						background:none !important;
					}
				}

                a {
                    display: block;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0 2rem;
                    color: rgba(#fff, 1);
                    cursor: pointer;
                    font-size: 1.4rem;
                    white-space: nowrap;
                    &:hover {
                        background: rgba(#000, 0.1) !important;
                        color: #fff;
                    }
                    span {
                        display: block;
                        font-size: 1.2rem;
                        padding: 0.2rem 0;
                        opacity: 0.8;
                    }
                }
            }
        }
    }
    .header-actions {
        order: 4;
        display: flex;
        margin-left: auto;
    }
    .header-notifications,
    .header-search-toggle,
    .header-basket {
        width: 4rem;
        height: 4rem;
        border-radius: 10%;
        background: transparent;
        //background-color: rgba(#000, 0.2);
        border: none;
        color: #fff;
        cursor: pointer;
        opacity: 0.8;
        margin-left: 1rem;
        position: relative;
        svg, i.fa {
            font-size: 2.5rem;
        }
        &:hover,
        &:focus {
            background-color: rgba(#000, 0.2);
            opacity: 1;
        }
        .button-count {
            position: absolute;
            bottom: 0;
            right: 0;
            background: $brand-color-1;
            color: #fff;
            font-size: 1.2rem;
            border-radius: 50%;
            width: 1.6rem;
            height: 1.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .header-search-toggle {
        @include mq($min-width: map-get($breakpoints, l)) {
            display: none;
        }
    }
    .header-user {
        width: 4rem;
        height: 4rem;
        background-color: rgba(#000, 0.4);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border: none;
        border-radius: 10%;
        margin-left: 1rem;
        cursor: pointer;
        color: white;
        font-size: 2em;
        opacity: 0.8;
        display: none;
        @include mq($min-width: map-get($breakpoints, m)) {
            display: block;
        }
        &+.header-panel {
            .notification-list {
                .notification-item {
                    padding: 1rem;
                    color: #000;
                }
            }
        }
    }
    .header-search {
        min-width: 20rem;
        margin-left: auto;
        margin-left: 0;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        display: none;
        &.is-open {
            display: block;
        }
        @include mq($min-width: map-get($breakpoints, l)) {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            margin-left: 1rem;
            display: block !important;
        }
        .header-search-query {
            position: relative;
            width: 100%;
            input {
                display: block;
                background: darken($theme-color-1, 5%);
                border: none;
                color: #fff;
                width: 100%;
                padding: 0 4rem 0 1.5rem;
				height: 6rem;
				border-radius:0;
                @include mq($min-width: map-get($breakpoints, m)) {
                    height: 4rem;
                }
                &::placeholder {
                    color: rgba(255, 255, 255, 0.8);
                }
            }
            button {
                position: absolute;
                right: 1rem;
                top: 50%;
                transform: translateY(-50%);
                padding: 0;
                background: transparent;
                color: #fff;
            }
        }
        .header-panel {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            min-width: unset;
            max-width: unset;
            width: auto;
            padding: 0;
            display: none;
            &.is-open {
                display: block;
            }
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    button {
                        display: block;
                        width: 100%;
                        padding: 1rem 1.5rem;
                        color: #000;
						background-color: transparent;
						border: 0px none transparent;
                        border-bottom: 1px solid #eee;
                        text-align: left;
                        font-family: $_opc-base-font-family;
                        &:hover {
							background: #f9f9f9;
                        }
					}
					&:last-child button{
						border-bottom: 0px none transparent;
					}
				}
            }
        }
    }
}

// header panel head
$_opc-header-panel-head-padding: 2rem !default;
$_opc-header-panel-head-bg: transparent !default;
$_opc-header-panel-head-color: inherit !default;
$_opc-header-panel-head-font-size: 1.8rem !default;
// header panel content      
$_opc-header-panel-body-padding: 0 !default;
$_opc-header-panel-body-bg: transparent !default;
$_opc-header-panel-body-color: inherit !default;
$_opc-header-panel-body-font-size: 1.4rem !default;
// header panel footer       
$_opc-header-panel-footer-padding: 2rem !default;
$_opc-header-panel-footer-bg: transparent !default;
$_opc-header-panel-footer-color: inherit !default;
$_opc-header-panel-footer-font-size: 1.4rem !default;
.header-panel-wrapper {
    @include mq($min-width: map-get($breakpoints, s)) {
        position: relative;
    }
}

.header-panel {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 100;
    display: none;
    background: #fff;
	box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    @include mq($min-width: map-get($breakpoints, s)) {
        min-width: 30rem;
        max-width: 30rem;
        width: 30rem;
        left: auto;
    }
    // header of basket item
    .header-panel-head {
        padding: 1rem;
        background: $_opc-header-panel-head-bg;
        color: $_opc-header-panel-head-color;
        font-size: $_opc-header-panel-head-font-size;
        border-bottom: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: space-between;
		span{
			overflow: hidden;
    		text-overflow: ellipsis;
		}
        span.header-panel-head-info {
            font-size: $_opc-header-panel-body-font-size;
        }
        .mini-basket-quantity {
            &::before {
                content: '(';
            }
            &::after {
                content: ')';
            }
        }
    }
    &.is-open {
        display: block;
    }
    .header-panel-body {
        max-height: 30rem;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0;
        background: $_opc-header-panel-body-bg;
        color: $_opc-header-panel-body-color;
        font-size: $_opc-header-panel-body-font-size;
        @include mq($min-width: map-get($breakpoints, s)) {
            position: relative;
        }
        .header-panel-body-inner {
            padding: 1rem;
        }
    }
    .header-panel-footer {
        border-top: 1px solid #ccc;
        padding: 1rem;
        background: $_opc-header-panel-footer-bg;
        color: $_opc-header-panel-footer-color;
        font-size: $_opc-header-panel-footer-font-size;
        .header-panel-footer-total {
            padding: 0 0 2rem;
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-weight: bold;
            b {
                font-weight: normal;
            }
        }
        // fullwidth button for checkout
        .opc-button {
            width: 100%;
            text-align: center;
        }
    }
}

.notification-list {
    list-style: none;
    margin: 0;
    padding: 0;
    .notification-item {
        position: relative;
        padding: 1rem 1rem 1rem 5rem;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #eee;
        cursor: pointer;
        &:hover {
            background: #f9f9f9;
        }
        .notification-item-icon {
            width: 3rem;
            height: 3rem;
            background: #eee;
            position: absolute;
            left: 1rem;
            top: 1rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .notification-item-title {
            font-weight: bold;
            flex-grow: 1;
        }
        .notification-item-date {}
        .notification-item-description {
            width: calc(100% - 4rem);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .notification-item-actions {
            margin-left: auto;
            button {
                width: 2rem;
                height: 2rem;
                border: none;
                background: transparent;
                color: #666;
                padding: 0.2rem;
                &:hover,
                &:focus {
                    color: crimson;
                }
            }
        }
    }
}

.sale-document-info {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    dt {
        width: 60%;
    }
    dd {
        width: 40%;
        text-align: right;
    }
}