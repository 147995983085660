/*
	Max width before this PARTICULAR table gets nasty. This query will take effect for any screen smaller than 760px and also iPads specifically.
	*/
	table.responsive{
		overflow-x: auto;
		overflow-wrap: break-word;
		table-layout: auto;
		th,td{
			@include mq($min-width: map-get($breakpoints, m)) {
				max-width: calc(800px / 2);
			}
			@include mq($min-width: map-get($breakpoints, l)) {
				max-width: calc(1000px / 2);
			}
			@include mq($min-width: map-get($breakpoints, xl)) {
				max-width: calc(1400px / 2);
			}
		}
		
	}
@media
only screen 
and (max-width: 760px), (min-device-width: 768px) 
and (max-device-width: 1024px)  {

	table.responsive{
		display: block;
		padding:1rem 0.2rem !important;
		table-layout: auto;
		
		/* Force table to not be like tables anymore */
		thead, tbody, th, td, tr {
			display: block;
			padding:0.4rem 0.2rem !important;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		tr {
		margin: 0 0 0 0;
		}
		
		/*
		tr:nth-child(odd) {
		background: #f8f8f8;
		}
		*/
    
		td {
			/* Behave  like a "row" */
			border: none;
			border-top: none;
			position: relative;
			padding-left: 50% !important;
			text-overflow: ellipsis;
			&:empty{
				padding:0rem 0.2rem !important;
			}
			&:-moz-only-whitespace{
				padding:0rem 0.2rem !important;
			}
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 0;
			left: 6px;
			width: 45%;
			padding: 0.4rem 0.2rem !important;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			font-weight:bold;
		}

		td:before { content:attr(data-label); }
	}

}

table.responsive{
	th,td{
		text-overflow: ellipsis;
		overflow: visible;
	}
}