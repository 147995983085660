$fileupload-error-color: #fa7979 !default;

@keyframes passing-through {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }
    30%,
    70% {
        opacity: 1;
        transform: translateY(0px);
    }
    100% {
        opacity: 0;
        transform: translateY(-40px);
    }
}

@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }
    30% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    10% {
        transform: scale(1.1);
    }
    20% {
        transform: scale(1);
    }
}

.opc-dropzone,
.opc-dropzone * {
    box-sizing: border-box;
}

.opc-dropzone {
    min-height: 150px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: white;
    padding: 1rem;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    &.dz-clickable {
        cursor: pointer;
        * {
            cursor: default;
        }
        .dz-message,
        .dz-message * {
            cursor: pointer;
        }
    }
    &.dz-started {
        .dz-message {
            display: none;
        }
    }
    &.dz-drag-hover {
        border-style: solid;
        box-shadow: 0 0 5px #ccc;
        .dz-message {
            opacity: 0.5;
        }
    }
    .dz-message {
        text-align: center;
        margin: 2em 0;
        align-self: center;
        width: 100%;
    }
    .dz-preview {
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: 1rem;
        min-height: 100px;
        .dz-details {
            z-index: 20;
            width: 120px;
            .dz-size {
                margin-bottom: 0;
                font-size: 12px;
                opacity: 0.5;
                span {
                    padding: 0;
                }
            }
            .dz-filename {
                white-space: nowrap;
                span {
                    padding: 0;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        &:hover {
            z-index: 1000;
            .dz-image {
                img {
                    transform: scale(1.05, 1.05);
                }
            }
        }
        &.dz-file-preview {
            .dz-image {
                border-radius: 0;
                background: #999;
                background: linear-gradient(to bottom, #eee, #ddd);
            }
            .dz-image-preview {
                opacity: 1;
            }
        }
        &.dz-image-preview {
            background: white;
            .dz-details {
                transition: opacity 0.2s linear;
            }
        }
        .dz-remove {
            font-size: 12px;
            text-align: left;
            display: inline-block;
            cursor: pointer;
            border: none;
            color: crimson;
            &:hover {
                text-decoration: underline;
            }
        }
        .dz-image {
            border-radius: 10px;
            overflow: hidden;
            width: 120px;
            height: 120px;
            margin: 0 auto;
            position: relative;
            display: block;
            z-index: 10;
            margin-bottom: 20px;
            background-color: #eee;
            img {
                display: block;
                max-width: 100%;
            }
        }
        .dz-success-mark,
        .dz-error-mark {
            pointer-events: none;
            opacity: 0;
            z-index: 500;
            position: absolute;
            display: block;
            top: 60px;
            left: 60px;
            margin-left: -15px;
            margin-top: -15px;
            svg {
                display: block;
                width: 30px;
                height: 30px;
            }
        }
        &.dz-success {
            .dz-success-mark {
                animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
            }
        }
        &.dz-error {
            .dz-error-mark {
                svg {
                    fill: red;
                }
                opacity: 1;
                animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
            }
            .dz-error-message {
                display: block;
            }
            &:hover {
                .dz-error-message {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
        &.dz-processing {
            .dz-progress {
                opacity: 1;
                transition: all 0.2s linear;
            }
        }
        &.dz-complete {
            .dz-progress {
                opacity: 0;
                transition: opacity 0.4s ease-in;
            }
        }
        &:not(.dz-processing) {
            .dz-progress {
                animation: pulse 6s ease infinite;
            }
        }
        .dz-progress {
            opacity: 1;
            z-index: 1000;
            pointer-events: none;
            position: absolute;
            -webkit-transform: scale(1);
            border-radius: 20px;
            overflow: hidden;
            height: 6px;
            margin-top: 0;
            margin-left: 0;
            top: 128px;
            left: 0;
            right: 0;
            background: rgba(#ccc, 0.8);
            .dz-upload {
                background: #333;
                background: linear-gradient(to bottom, #666, #444);
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 0;
                transition: width 300ms ease-in-out;
            }
        }
        .dz-error-message {
            pointer-events: none;
            z-index: 1000; //position: absolute;
            display: block; //display: none;
            //opacity: 0;
            transition: opacity 0.3s ease;
            border-radius: 1rem;
            font-size: 12px;
            left: 0;
            position: relative;
            width: 120px;
            background: $fileupload-error-color;
            color: #fff;
            span {
                display: block;
                padding: 1rem;
                margin-top: 1rem;
                position: relative;
                &:empty {
                    display: none;
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: -6px;
                    left: 54px;
                    width: 0;
                    height: 0;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-bottom: 6px solid $fileupload-error-color;
                }
            }
        }
    }
    &[data-op-type="single"] {
        height: 4rem;
        min-height: 4rem;
        max-height: 4rem;
        .dz-preview {
            padding: 0;
            margin: 0;
            &:not(:first-of-type) {
                display: none;
            }
        }
        .dz-image {
            display: none;
        }
        .dz-details {
            display: flex;
            width: auto;
            .dz-filename {
                order: 1;
                font-size: 1.4rem;
            }
            .dz-size {
                order: 2;
                margin-left: 0.5rem;
                font-size: 1.4rem;
            }
        }
        .dz-remove {
            display: none;
        }
        .dz-error-message,
        .dz-error-mark,
        .dz-success-mark {
            display: none !important;
        }
    }
}