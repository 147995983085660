div[data-op-type="tablesort"] {
	.item-list-head .list-item-column{
		opacity:0.5;

		span {
			display:inline-block;
			padding-left: 0.5rem;

			&::before {
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				content: "";
				display: block;
				width: 1rem;
				height: 1rem;
			}
		}

		&[data-op-sort-direction] {
			cursor: pointer;
		}

		&[data-op-sort-direction=""] 

			span::before {
			background-image: inline-svg(
				$_opc-table-sort-icon-none,
				$_opc-table-sort-icon-active-color
			);
		}

		&[data-op-sort-direction="asc"]{
			opacity:1;

			span::before {
				background-image: inline-svg(
					$_opc-table-sort-icon-asc,
					$_opc-table-sort-icon-active-color
				);
			}
		}

		&[data-op-sort-direction="desc"]{
			opacity:1;

			span::before {
				background-image: inline-svg(
					$_opc-table-sort-icon-desc,
					$_opc-table-sort-icon-active-color
				);
			}
		}
	}
}

@include mq($max-width: map-get($breakpoints, m)) {
	.list-item-column span {
		max-width: 50%;
	}
	.list-item-column div.marker span {
		max-width: 100%;
	}
}


table {
	tbody {
		tr {
			border-bottom: 1px solid #efefef;
		}
	}
}