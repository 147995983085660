.opc-dropzone {
	border: 1px dashed rgba(0,0,0,0.2);
	.dz-preview {
		.dz-details {
            z-index: 1;
        }
        &:hover {
            z-index: 3;
        }
        .dz-image {
			z-index: 0;
			background: #ddd;
			&:before {
				font-family: "Font Awesome 6 Pro";
				content: "\f15b";
				position: absolute;
				right: 34%;
				top: 18%;
				font-size: 3.5em;
				color: #444;
			}
        }
        .dz-success-mark,
        .dz-error-mark {
            z-index: 2;
        }
        .dz-progress {
			z-index: 3;
        }
        .dz-error-message {
            z-index: 3;
        }
    }
}