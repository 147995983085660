.art-search-list {
    .panel-body {
        .list-item-column {

			&:nth-last-child(n + 8):first-child ~ :nth-last-child(1),
			&:nth-last-child(n + 9):first-child ~ :nth-last-child(2),
			&:nth-last-child(n + 10):first-child ~ :nth-last-child(2),
			&:nth-last-child(n + 10):first-child ~ :nth-last-child(3),
			&:nth-last-child(n + 11):first-child ~ :nth-last-child(2),
			&:nth-last-child(n + 11):first-child ~ :nth-last-child(3),
			&:nth-last-child(n + 11):first-child ~ :nth-last-child(4) {
				text-align: right;
			}

			.opc-form-field{
				margin-bottom:0;
			}

			.opc-inline-form{
				margin-left: 0.5em;
				margin-right: 0.5em;

				.opc-input{
					text-align:right;
				}
			}
		}
	}
}
