/*###############################################

@Title: Popover
@Description:
This file contains the popover styles

###############################################*/

$_opc-popover-color-background: #fff !default;
$_opc-popover-color-shadow: rgba(0,0,0,.2) !default;

$_opc-popover-border-color: rgba(0,0,0,.2) !default;
$_opc-popover-border-color-hide: #fff !default;
$_opc-popover-border-radius: 3px !default;

$_opc-popover-ovleray-zindex: 1060 !default;

.opc-popover{
    display: inline-block;
    .popover-content{
        display: none;
    }
}

.opc-popover-content[data-op-caller-uuid]{
    position: absolute;
    top: -1000;
    left: -1000;
    z-index: $_opc-popover-ovleray-zindex;
    display: block;
    padding: 1px;
    text-align: left;
    white-space: normal;
    background-color: $_opc-popover-color-background;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid $_opc-popover-border-color;
    border-radius: $_opc-popover-border-radius;
    -webkit-box-shadow: 0 5px 10px $_opc-popover-color-shadow;
    box-shadow: 0 5px 10px $_opc-popover-color-shadow;

    > .overlay-content{
        padding: 9px 14px;
    }

    > .overlay-arrow{
        position: absolute;
        display: block;
        top: -11px;
        left: 50%;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 11px;
        margin-left: -11px;
        border-top-width: 0;
        border-bottom-color: $_opc-popover-border-color;

        &:before{
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 10px;
            top: 1px;
            margin-left: -10px;
            content: " ";
            border-top-width: 0;
            border-bottom-color: $_opc-popover-border-color-hide;
        }
    }
}