/*###############################################

@Title: Offcanvas Nav
@Description:


###############################################*/


/*
 Types:
 - full: for a fullscreen menu
 - side: for a side menu that moves the content to the right 
*/

$offcanvas-type: 'side'; // side or full
$offcanvas-width: 100%; // width of side menu
$offcanvas-color: #fff;
.offcanvas-menu-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background: $theme-color-1;
    overflow-x: hidden;
    overflow-y: auto;
    overflow: hidden;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    @if $offcanvas-type=='full' {
        @extend %mw;
        right: 0;
        // hide when not active
        visibility: hidden;
        z-index: -10;
        opacity: 0;
        // show when offcanvas-menu-open class is set to body
        .offcanvas-menu-open & {
            visibility: visible;
            z-index: 1000;
            opacity: 1;
        }
    }
    @else if $offcanvas-type=='side' {
        width: $offcanvas-width;
        left: -$offcanvas-width;
        .offcanvas-menu-open & {
            transform: translateX(100%);
            z-index: 1000;
        }
    }
    .offcanvas-menu-inner {
        @extend %mw-inner;
        .opc-mini-label {
            padding: 1rem 2rem 0;
            margin-bottom: 0.5rem;
            color: #fff;
            margin-top: 2rem;
            font-size: 1.2rem;
            font-weight: normal;
            &:first-child {
                margin-top: 0;
            }
        }
        display: flex;
        height: 100%;
        flex-direction: column;
    }
    .offcanvas-menu-head {
        //background: red;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2rem;
        flex-shrink: 0;
        height: 8rem;
        //		background: green;
        .offcanvas-brand {
            width: 100%;
            a {
                display: inline-block;
            }
            img {
                width: 100%;
                width: auto;
                max-width: 18rem;
                max-height: 6rem;
                height: 6rem;
            }
        }
        .offcanvas-menu-close {
            background: transparent;
            color: rgba(#fff, 0.8);
            padding: 0;
            width: 3rem;
            svg, i.fa {
                margin: 0;
                font-size: 3rem;
            }
        }
    }
    .offcanvas-menu-body {
        //background: red;
        position: absolute;
        top: 8rem;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .offcanvas-panel {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.25s ease-in-out;
        opacity: 0;
        //background: yellow;
        background: $theme-color-1;
        transform: translateX(-100%);
        &[data-op-panel="user"] {
            .offcanvas-panel-head h3 {
                img {
                    height: 5rem;
                    width: 5rem;
                    border-radius: 10%;
                    float: right;
                    margin-top: -0.6rem
                }
            }
        }
        .offcanvas-panel-head {
            padding: 2rem;
            position: relative;
            background: rgba(#000, 0.1);
            h3 {
                padding-left: 3rem;
                color: #fff;
                margin-bottom: 0;
                &+form {
                    margin-top: 2rem;
                }
                line-height: 3.8rem;
                span {
                    display: block;
                    font-size: 0.6em;
                }
            }
            .panel-back {
                position: absolute;
                left: 2rem;
                top: 1.75rem;
                max-width: 4rem;
                height: 4rem;
				display:inline-block;
				overflow:hidden;
                color: rgba(#fff, 0.8);
                background: transparent;
                border: none;
                font-size: 4rem;
				padding:0;
                cursor: pointer;
            }
        }
        .panel-search-query {
            position: relative;
            width: 100%;
            input {
                display: block;
                background: rgba(#fff, 0.2);
                border: none;
                color: #fff;
                width: 100%;
                height: 5rem;
                &::placeholder {
                    color: rgba(255, 255, 255, 0.8);
                }
            }
            button {
                position: absolute;
                right: 1rem;
                top: 50%;
                transform: translateY(-50%);
                padding: 0;
                background: transparent;
                color: #fff;
            }
        }
        ul.navigation-level-3 {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                a {
                    display: block;
                    padding: 1.5rem 2rem;
                    margin: 0 !important;
                    color: rgba(#fff, 0.8);
                }
				&.is-active a, a:hover{
					background: rgba(#000, 0.1);
				}

				&.is-open > a{
					color: rgba(#fff, 0.8);
				}
            }
        }
        .navigation-level-2 {
			display:block;
            >li {
                &:first-child {
                    margin-top: 0;
                }
                >a, &.is-open>a {
                    padding: 0;
                    height: auto;
                    padding-left: 2rem;
                    padding-bottom: 1rem;
                    padding-top: 1rem;
                    pointer-events: none;
					font-weight:normal;
                    color: rgba(#fff, 0.8);
                    //@extend .small;
                    margin-bottom: 0;
                    text-transform: uppercase;
                    opacity: 1;
                    background-color: rgba(#fff, 0.05);
                    &.logout {
                        cursor: pointer;
                        color: #fff;
                        pointer-events: auto;
                    }
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }
        .navigation-level-3 {
			display:block;
            li {
                a {
                    padding-left: 2rem;
                    @extend .h3;
                }
				&.is-active a{
					font-weight: 600;
				}
                &:last-child {
                    border-bottom: none;
					margin-bottom: 1.5rem;
                }
                .navigation-level-4 {
                    a {
                        padding-left: 4rem;
                    }
                }
            }
        }
        &.offcanvas-panel-root {
            transform: translateX(0);
            opacity: 1;
            div.opc-navigation {
                min-height: calc(100% - 6rem);
            }
            li {
                border-bottom: 1px solid rgba(#fff, 0.3);
				margin-bottom: 0rem;
                a {
                    display: block;
                    padding: 2rem;
                    position: relative;
                    margin: 0;
                    @extend .h3;
                    color: rgba(#fff, 0.8);
                    &:hover {
                        background: rgba(#000, 0.1);
                    }
                    span {
                        display: block;
                        font-size: 0.6em;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        right: 2rem;
                        top: 50%;
                        margin-top: -1rem;
                        display: block;
                        width: 2rem;
                        height: 2rem;
                        background-image: inline-svg($op-icon-angle-right-svg, '#fff');
                        background-size: 20px;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }
                &[data-op-menu="user"] {
                    display: none;
                }
            }
            .offcanvas-user {
                padding: 0 2rem;
                background: rgba(#000, 0.1);
                a {
                    height: 6rem;
                    display: flex;
                    align-items: center;
                    @extend .h3;
                    color: #fff;
                    margin: 0;
                    &::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 50%;
                        margin-top: -1rem;
                        display: block;
                        width: 2rem;
                        height: 2rem;
                        background-image: inline-svg($op-icon-angle-right-svg, '#fff');
                        background-size: 20px;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }
                img {
                    border-radius: 10%;
                    width: 4rem;
                    height: 4rem;
                    margin-right: 1rem;
                }
            }
        }
        &.is-open {
            transform: translateX(0);
            opacity: 1;
        }
        .current-selection {
            margin-bottom: 0;
            padding: 2rem;
            color: #fff;
            @extend .h2;
        }
    }
    .offcanvas-item {
        a {
            display: block;
            color: rgba($offcanvas-color, 0.8);
            padding: 2rem;
            &:hover {
                color: rgba($offcanvas-color, 1);
            }
        }
    }
    .opc-navigation {
        color: #fff;
        a {
            color: rgba($offcanvas-color, 0.8);
            &:hover {
                color: rgba($offcanvas-color, 1);
                background: rgba(#000, 0.1);
            }
            &.is-active {
                background: $brand-color-1;
            }
        }
    }
}

.viewport {
    @if $offcanvas-type=='side' {
        .offcanvas-menu-open & {
            transform: translateX($offcanvas-width);
        }
    }
}