/*
	Admin-0600.oc
	Log-Level Formatieren
*/

.LogLevel {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;

    &.Undefined,.Debug {
        color: #212529;
        background-color: #f8f9fa;
    }
    &.Info {
        color: #fff;
        background-color: #17a2b8;
    }
    &.Warning {
        color: #212529;
        background-color: #ffc107;
    }
    &.Error {
        color: #fff;
        background-color: #dc3545;
    } 

}

.alert {
    &.warning {
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
        position: relative;
        padding: 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 0.25rem;
    }
}