/* Meldungen unten Rechts */

.opc-notifications-notifier {
    .notifications-message {
        border-radius: 0;
        &.is-warning {
            background: $warning-color;
        }
    }
}


/* Modal */

.opc-notifications .notifications-dialog .notifications-header:not(:empty) {
    .view-title {
        font-weight: $_opc-headline-font-weight;
        font-family: $_opc-headline-font-family;
        font-size: 1.6rem;
        line-height: 1.25;
        margin: 0px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
    }
    border-bottom:1px solid #e3e3e3;
    margin-bottom:1em;
}


/* Modal Fullscreen */
.opc-notifications.is-maximized {
	.notifications-dialog {
		width: calc(100% - 4em) !important;
		height: calc(100% - 4em) !important;
		top: 2em !important;
		.notifications-commands {
			background-color: #fff;
		}
		.notifications-body {
			padding: 0 2rem 2rem;
			.notifications-content{
				left: 2rem;
				right: 2rem;
				bottom: 2rem;
				
			}
		}
	}
}
.opc-notifications.is-maximized .notifications-body .notifications-content {
    left: 2rem;
    top: 8rem;
}


/* Confirm */

.opc-notifications[data-op-type="confirm"] {
    .notifications-dialog {
        background-color: #fff;
        .notifications-commands {
            .notifications-close {
                background-image: inline-svg($op-icon-cross-svg, rgb(5, 5, 5)) !important;
            }
        }
        .notifications-header {
			background-color: #eee;
            border-bottom: none;
            min-height: 5.5rem;
            margin-bottom: 1em;
        }
        .notifications-body {
            height: auto;
            min-height: auto;
        }
        .notifications-footer {
            background-color: #fff;
            .notifications-buttons {
                .notifications-ok {
                    background: $brand-color-1;
                }
				.notifications-cancel {
					@extend .opc-button[data-op-style="secondary-lined"];
				}
            }
        }
    }
    &[data-op-style="warning"] {
        .notifications-dialog {
            .notifications-footer {
                .notifications-buttons {
                    .notifications-ok {
                        background: $warning-color;
                        color: #000;
                    }
                }
            }
        }
    }
    &[data-op-style="error"] {
        .notifications-dialog {
            .notifications-footer {
                .notifications-buttons {
                    .notifications-ok {
                        background: $error-color;
                        color: #fff;
                    }
                }
            }
        }
    }
}

@include mq($max-width: map-get($breakpoints, m)) {
	
	.opc-notifications.is-maximized .notifications-dialog{
		width: calc(100% - 1em) !important;
		height: calc(100% - 1em) !important;
		top: 0.5em !important;
	}
	.opc-notifications.is-maximized {
		.notifications-dialog {
			.notifications-body {
				padding: 0 1rem 1rem;
				.notifications-content{
					left: 1rem;
					right: 1rem;
					bottom: 1rem;
					top: 7rem;
				}
			}
		}
	}
}