.zefix-hinweis {
  color: #aaa;
  font-size: 1rem;
}

.mh-rating {
  padding: 2px 10px;
  &.mh-rating-0 {
	background-color: $color-greylight;
  }
  &.mh-rating-10 {
    background-color: $success-color;
    color: white;
  }
  &.mh-rating-20 {
    background-color: $warning-color;
  }
  &.mh-rating-30 {
    background-color: $error-color;
    color: white;
  }    
}

