/*
	Activity-0020.oc
	Spaltenbreite Liste
*/
.activity-0020.opc-item-list .list-item-column {
    &:nth-child(5) {
		line-clamp: 3;
		-webkit-line-clamp: 3;
	}
	svg{
		font-size:1.3em;
	}
}


/*
	Activity-0200.oc
	Spaltenbreite Liste
*/
.activity-0200.opc-item-list .list-item-column {
    &:nth-child(6) {
		line-clamp: 3;
		-webkit-line-clamp: 3;
	}
	svg{
		font-size:1.3em;
	}

	.opc-form-field{
		margin-bottom:0;
	}
}

/*
	Activity-0202.oc
	Spaltenbreite Routeplaner
*/
.activity-0202.opc-item-list{
	.item-list-body .list-item{
		align-items:flex-start;
	}

	.list-item-column {
		&:nth-child(3) {
			line-clamp: 1;
			-webkit-line-clamp: 1;
		}
	}
}

/*
Icons auf Kalender
*/
[data-op-fullcalendar="1"]{
	.fc-scrollgrid-section-header .fc-scroller{
		overflow:hidden !important;
	}

	.fc-view-harness{
		min-height:100%;
	}
}
.fc-event{
	.fc-event-title::before{
		font-family: 'Font Awesome 6 Pro';
		padding: 0px 3px 0px 2px;

	}
	&.activityTypeSearch .fc-event-title::before{
		content: "\f002";
		font-weight:bold;
	}
	&.activityType1 .fc-event-title::before{
		content: "\f456";
		font-weight:bold;
	}
	&.activityType2 .fc-event-title::before{
		content: "\f0f3";
		font-weight:bold;
	}
	&.activityType6 .fc-event-title::before{
		content: "\f0ad";
		font-weight:bold;
	}
	&.activityType7 .fc-event-title::before{
		content: "\f674";
		font-weight:bold;
	}
	&.activityType8 .fc-event-title::before{
		content: "\f073";
		font-weight:bold;
	}
	&.activityType9 .fc-event-title::before{
		content: "\f46c";
		font-weight:bold;
	}
}

/*
Kalender Legende
*/
.activity_calendarlegend{
	margin-top:1rem;
	span{
		margin-right:0.5em;
	}
}

/*
Kalender Events
*/
.fc-event {
    border: inherit;
}

.activity_calendar_form{
	.opc-form-field {
        margin-bottom: 1em;
    }
    .opc-row .opc-col {
        margin-bottom: 0px;
	}
	@include mq($max-width: map-get($breakpoints, m)) {
		label[for=nextprev]{
			display:none;
		}
	}

}

//ContactPlan.Location komplett ausblenden: Application-Parameter 
body.no-contactplan-location .contactplan-location{
	display:none !important;
}

#directions {
	.list-item-column {
		flex-wrap: wrap !important;
		align-items: center !important;
		flex-direction: column !important;
	}
}