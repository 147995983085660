/*
	ECRM Settings 
*/

#settingscontainer{
	.list-item-column{
		input,select,textarea{
			width:100%;
		}

		&:nth-child(2){
			padding-right:2rem;
		}

	}
}