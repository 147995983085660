.opc-item-list {
	
    .list-item,
    .item-list-head {
        display: block;
        color: #000;
        padding: 0.5rem 0.2rem;
        .list-item-column {
			min-width:0;
            display: flex;
			padding: 0.4rem 0.1rem;
			margin-bottom: 0.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
			width: 100%;
			align-items:flex-start;
			flex-wrap : wrap;
			gap: 0.8rem;
			//Umbruch bei Spalten mit Bildern verhindern
			&.nowrap{
				white-space: nowrap;
			}

			span.image-info{
				padding:5px;
				padding-left:0px;
				span img{
					max-height: 25px;
					max-width: 25px;
					margin-right:5px;
				}
			}

			svg{
				margin-right: 0.2em;
			}
            .fa-icon {
                display: inline-block;
                margin-right: 0.4em;
                &:before {
                    font-family: 'Font Awesome 6 Pro';
                    font-size: 1.2em;
                }
			}

			.image{
				text-align:center;
				display:block;
				width:100%;

				img {
					max-width: 95%;
					max-height: 40px;
					vertical-align: middle;
				}
			}
            &[data-column-align="left"] {
				text-align:left;
			}
            &[data-column-align="right"] {
				text-align:right;
				padding-right:0.5rem !important;
			}
            &[data-column-align="center"] {
				text-align:center;
			}
            &[data-column-width="0.1"] {
                flex: 0.1 !important;
            }
            &[data-column-width="0.2"] {
                flex: 0.2 !important;
            }
            &[data-column-width="0.25"] {
                flex: 0.25 !important;
            }
            &[data-column-width="0.3"] {
                flex: 0.3 !important;
            }
            &[data-column-width="0.33"] {
                flex: 0.33 !important;
            }
            &[data-column-width="0.4"] {
                flex: 0.4 !important;
            }
            &[data-column-width="0.5"] {
                flex: 0.5 !important;
            }
            &[data-column-width="0.6"] {
                flex: 0.6 !important;
            }
            &[data-column-width="0.66"] {
                flex: 0.66 !important;
            }
            &[data-column-width="0.7"] {
                flex: 0.7 !important;
            }
            &[data-column-width="0.75"] {
                flex: 0.75 !important;
            }
            &[data-column-width="0.8"] {
                flex: 0.8 !important;
            }
            &[data-column-width="0.9"] {
                flex: 0.9 !important;
            }
            &[data-column-width="1"] {
                flex: 1 !important;
            }
			&[data-column-width="1.5"] {
                flex: 1.5 !important;
            }
            &[data-column-width="2"] {
                flex: 2 !important;
            }
            &[data-column-width="3"] {
                flex: 3 !important;
            }
            &[data-column-width="4"] {
                flex: 4 !important;
            }
            &[data-column-width="5"] {
                flex: 5 !important;
            }
            &[data-column-width="6"] {
                flex: 6 !important;
            }
            &[data-column-width="7"] {
                flex: 7 !important;
            }
            &[data-column-width="8"] {
                flex: 8 !important;
            }
            &[data-column-width="9"] {
                flex: 9 !important;
            }
            &[data-column-width="10"] {
                flex: 10 !important;
			}
			&[data-column-width="11"] {
                flex: 11 !important;
			}
            &[data-column-width="12"] {
                flex: 12 !important;
			}
            &[data-column-width="13"] {
                flex: 13 !important;
			}
            &[data-column-width="14"] {
                flex: 14 !important;
			}
            &[data-column-width="15"] {
                flex: 15 !important;
			}
            &[data-column-width="16"] {
                flex: 16 !important;
			}
            &[data-column-width="17"] {
                flex: 17 !important;
			}
            &[data-column-width="18"] {
                flex: 18 !important;
			}
            &[data-column-width="19"] {
                flex: 19 !important;
			}
            &[data-column-width="20"] {
                flex: 20 !important;
			}
            &[data-column-width="21"] {
                flex: 21 !important;
			}
            &[data-column-width="22"] {
                flex: 22 !important;
			}
            &[data-column-width="23"] {
                flex: 23 !important;
			}
            &[data-column-width="24"] {
                flex: 24 !important;
			}
            &[data-column-width="25"] {
                flex: 25 !important;
			}
            &[data-column-width="26"] {
                flex: 26 !important;
			}
            &[data-column-width="27"] {
                flex: 27 !important;
			}
            &[data-column-width="28"] {
                flex: 28 !important;
			}
            &[data-column-width="29"] {
                flex: 29 !important;
			}
            &[data-column-width="30"] {
                flex: 30 !important;
			}

			@include mq($max-width: map-get($breakpoints, s)) {
				&[data-column-hide~="s"] {
					display:none;
				}
			}
			@include mq($max-width: map-get($breakpoints, m)) {
				padding: 0.1rem 0;
				.image{
					display:inline-block;
					width:auto;
				}
				&:empty{
					display:none;
				}
			}

			@include screen(map-get($breakpoints, s),map-get($breakpoints, m)) {
				&[data-column-hide~="m"] {
					display:none;
				}
			}

			@include screen(map-get($breakpoints, m),map-get($breakpoints, l)) {
				&[data-column-hide~="l"] {
					display:none;
				}
			}

           
            >.opc-button[data-op-type="link"]{
                display: inline-block;
                text-overflow: ellipsis;
                max-width: 100%;
                height: auto;
				margin: -0.5rem 0;
				padding:0;
            }

			// Text abgekuerzt mit Icon
			span.text-short{
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				&:before{
					font-family: 'Font Awesome 6 Pro';
					content: "\f036";
					margin-right: 0.4rem;
				}
			}
        }
    }
    .item-list-head {
        display: none;
        font-weight: 600;
    }
    .list-item {
		&[data-op-href],&.is-active[data-op-href]{
			cursor:pointer;
		}
		border-bottom: 1px solid #efefef;

		&.passive{
			opacity: 0.7;
			filter: grayscale(1);
		}

        .list-item-column::before {
            content: attr(data-label) '\A' attr(data-label2);
            display: inline-block;
            flex: 0 0 40%;
            font-weight: 600;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
		}
		&:hover {
			padding: 0.5rem 0.2rem;
            background: transparent;
            &:link,
            &[data-op-linkedfield],
			&[data-op-view],
			&[data-op-link] {
                background: #eee;
                cursor: pointer;
            }
		}
		&.is-active {
            background-color: rgb(228, 226, 226);
            //background-color: #eaead3;
            cursor: default;
            button:hover {
                background: #fff;
            }
        }
    }
}

@include mq($min-width: map-get($breakpoints, m)) {
    .opc-item-list {
        .item-list-head,
        .list-item {
            display: flex;
			align-items: center;
            .list-item-column {
				display: block;
                flex: 1;
				margin:0;
                &::before {
                    display: none;
                }
            }
        }
    }
}

@include mq($max-width: map-get($breakpoints, m)) {
	.opc-item-list .list-item .list-item-column:empty{
		display:none;
	}
	.opc-item-list .list-item .list-item-column:-moz-only-whitespace{
		display:none;
	}
}


@include mq($min-width: map-get($breakpoints, m)) {
	.opc-item-list[data-op-autolayout="table"]{
		visibility:hidden;
		display: table !important;
		table-layout: auto;
		border-collapse:collapse !important;
		width: 100%;
		.item-list-head{
			display: table-row-group !important;
			vertical-align: middle !important;
			.list-item-column{
				padding: 0.9rem 0 !important;
			}
		}
	
		.item-list-body{
			display: table-row-group !important;
			vertical-align: middle !important;
		}
	
		.list-item{
			display: table-row !important;
		}
		.list-item .list-item-column[data-op-style="context-menu"], .item-list-head .list-item-column[data-op-style="context-menu"]{
			width:3.7rem !important;
			padding-left:1rem !important;
		}
		[data-op-style="context-menu-dropdown"] .list-item{
			display:flex !important;

		}
	
		.list-item-column{
			span.sort-dir-span{
				display:none;
			}
			display: table-cell !important;
			width: auto !important;
			padding: 0.65em 0 !important;
			min-width:4rem !important;
			vertical-align: middle !important;
			// Spaltenbreite
			&:has(svg,span,i[class^="fa-"]){
				white-space: nowrap !important;
				padding-left: 5px !important;
				padding-right: 5px !important;
			}
			&[data-op-sort-attr]:not(:has(.sort-dir-span)){
				padding-right:15px;
			}
			i[class^="fa-"]{
				width:16px;
				margin-right: 0.2em;
				//display:inline-block;
			}
		}
		.list-item-column:first-child{
			padding-left:0.5rem !important;
		}
		.list-item-column:last-child{
			padding-right:0.5rem !important;
		}

	}

}