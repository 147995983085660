/* ECRM Boolean Element */

.opc-boolean label {
    margin: 0;
	height: 4rem;

	.buttonbackground {
		height: 2.5rem;
		border-radius: 1.25rem;
		position: relative;
		margin: 0.7rem auto 0 auto;

		.buttonslider {
			width: 3rem;
			height: 3rem;
			top: 1.8rem;
			border-radius: 50%;
			border: 1px solid #aaa;
			position: absolute;
			left: 0;
			transition: all 0.1s linear;
			display: block;
		}
	}
}

.opc-boolean input[id$="TRUE"][type=radio]:disabled ~ label[for$="FALSE"] .buttonbackground,.opc-boolean input[id$="TRUE"][type=radio][readonly]~ label[for$="FALSE"] .buttonbackground{
	background-color: $brand-color-1 !important;
	filter: grayscale(1);
}
.opc-boolean input[id$="TRUE"][type=radio][readonly]~ label{
	pointer-events:none;
	cursor:not-allowed;
}

.opc-boolean input[id$="FALSE"][type=radio]:checked~label[for$="TRUE"] .buttonbackground{
	.buttonslider {
		left: -1px;
	}
}

.opc-boolean input[id$="TRUE"][type=radio]:checked~label[for$="FALSE"] .buttonbackground{
	&:before {
		content: "\f00c";
		font-family: "Font Awesome 6 Pro";
		font-size: 1rem;
		margin-left:0.6rem;
		color: $_opc-button-primary-color;
	}

	.buttonslider {
		-webkit-transform: translateX(-2.7rem) translateY(-2.1375rem);
		transform: translateX(-2.7rem) translateY(-2.1375rem);
	}
}