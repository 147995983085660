/*###############################################

@Title: Icons
@Description:
This file contains the SVG icon styles and raw icons

###############################################*/

[class^="op-icon-"], div[class*=" op-icon-"],
[class^="opc-icon-"], div[class*=" opc-icon-"],
[class="opc-icon"] {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    font-size: 1.2em;
}

svg {
    pointer-events: none;
    title {
        display:none !important;
    }
}


$op-icon-cross-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z "/></svg>' !default;
$op-icon-check-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z "/></svg>' !default;
$op-icon-angle-right-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 12.96875 4.28125 L 11.53125 5.71875 L 21.8125 16 L 11.53125 26.28125 L 12.96875 27.71875 L 23.96875 16.71875 L 24.65625 16 L 23.96875 15.28125 Z "/></svg>' !default;
$op-icon-angle-left-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path d="M19.031 4.281l-11 11-0.688 0.719 0.688 0.719 11 11 1.438-1.438-10.281-10.281 10.281-10.281z"></path></svg>' !default;
$op-icon-angle-down-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 4.21875 10.78125 L 2.78125 12.21875 L 15.28125 24.71875 L 16 25.40625 L 16.71875 24.71875 L 29.21875 12.21875 L 27.78125 10.78125 L 16 22.5625 Z "/></svg>' !default;
$op-icon-stock-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><circle cx="50" cy="50" r="40" /></svg>' !default;
$op-icon-sort-none: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24.98 32" width="32" height="32"><path d="M28,18.41A1.47,1.47,0,0,0,26.93,18H5.07a1.5,1.5,0,0,0-1.1.46,1.55,1.55,0,0,0,0,2.2L14.9,31.54a1.55,1.55,0,0,0,2.2,0L28,20.61a1.52,1.52,0,0,0,0-2.2Z" transform="translate(-3.51)"/><path d="M4,13.59a1.47,1.47,0,0,0,1.09.46H26.93a1.5,1.5,0,0,0,1.1-.46,1.55,1.55,0,0,0,0-2.2L17.1.46a1.55,1.55,0,0,0-2.2,0L4,11.39a1.52,1.52,0,0,0,0,2.2Z" transform="translate(-3.51)"/></svg>' !default;
$op-icon-sort-asc: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 18" width="32" height="32"><path d="M31.41,21.59l-14-14a2,2,0,0,0-2.82,0l-14,14a2,2,0,0,0,0,2.82A2,2,0,0,0,2,25H30a2,2,0,0,0,1.41-3.41Z" transform="translate(0 -7)"/></svg>' !default;
$op-icon-sort-desc: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 18" width="32" height="32"><path d="M31.41,7.59A2,2,0,0,0,30,7H2a2,2,0,0,0-1.41.59,2,2,0,0,0,0,2.82l14,14a2,2,0,0,0,2.82,0l14-14a2,2,0,0,0,0-2.82Z" transform="translate(0 -7)"/></svg>' !default;