.opc-item-list {
    .list-item,
    .item-list-head {
        .list-item-column {
			&[data-op-style="context-menu"] {
				text-align:center;
				padding: 0.4rem 0rem;
                flex-grow: 0 !important;
                flex-shrink: 0 !important;
                flex-basis: 3.7rem !important;
                min-width: inherit !important;
                margin-left: 1rem !important;
                @include mq($max-width: map-get($breakpoints, m)) {
                    display: block;
                    width: 3.7rem;
                    height: auto;
                    position: absolute;
                    right: 0.5rem;
                    top: 0.5rem;
                    padding: 0;
                    margin: 0;
                    &:before {
                        display: none;
                    }
                }
            }
        }
        @include mq($max-width: map-get($breakpoints, m)) {
            position: relative;
        }
    }
}

td[data-op-style="context-menu"],th[data-op-style="context-menu"] {
	width:3.5rem;
	text-overflow:unset;
}


[data-op-style="context-menu"] {
	.opc-button {
		pointer-events: none;
        width: 3.7rem;
        height: 3.7rem;
		border-radius: 50%;
		&[data-op-size="small"]{
			width: 3.5rem;
			height: 3.5rem;
		}
        &:before {
            content: "\f142";
            font-family: "Font Awesome 6 Pro";
            font-size: 2.6rem;
        }
        &:after {
            display: none;
        }
        background-color: rgba(255, 255, 255, 0.8);
    }
    &:hover {
        .opc-button {
            background-color: $brand-color-2;
            color: white;
        }
    }
}

.opc-dropdown-button-content[data-op-caller-uuid][data-op-style="context-menu-dropdown"] > .overlay-content, .opc-dropdown-button-content[data-op-caller-uuid] > .overlay-content[data-op-style="context-menu-dropdown"] {
	>a, div, span {
		padding-left: 3.2rem;
		margin: 0;
		position: relative;
		color: rgba(0, 0, 0, 0.8);
		svg[data-icon] {
			position: absolute;
			left: 0.8rem;
			top: 1rem;
			width: 1.6rem;
			height: 1.6rem;
		}
	}
}