/* FontAwesome */

.fontawesome-i2svg-pending body {
	visibility: hidden;
}

.fontawesome-i2svg-active body {
	visibility: initial;
}

[data-op-item-marker-value].fa-icon {
    display: inline-block;
    margin-right: 0.6em;
    &:before {
        font-family: 'Font Awesome 6 Pro';
        font-size: 1.2em;
    }
}

/* HR */

hr {
    border: none;
    border-bottom: 1px solid #ccc;
    margin: 2em 0;
}


/* PRE */

pre {
    font-family: monospace, monospace;
    font-size: 1em;
    line-height: 1.4;
}


/* Pull Right */

.pull-right {
    float: right !important;
}


/* Aktives Menu hervorheben. */

.mainnav-inner li.is-active {
    font-weight: 600;
}

.was-message-code,
.was-message-addition {
    display: none;
}


/* Chart Definition */

.chart {
    svg, i.fa {
        pointer-events: auto;
    }
    table {
        width: auto;
    }
}

strong {
    font-weight: 600;
}


/* Lade-Animation */
.siteloader{
	width: 100vw;
    background: rgba(239,239,239,0.6);
    height: 100vh;
    z-index: 99999999;
    position: fixed;
}

.stacked-layer,.content,.siteloader{
	&.is-progressing{
		min-height: 6px;
		overflow-x: hidden;
		
		>*:not(.stacked-layer) {
			opacity: 0.3;
		}
		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			background-color: darken($brand-color-1,3%);
			width: 100%;
			height: 6px;
		}
		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: -20%;
			bottom: 0;
			//will-change: left, right;
			background-color: lighten($brand-color-1,4%);
			width: 20%;
			height: 6px;
			//animation: is-progressing 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
			animation: is-progressing-animation 2s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
		}
	}
}
main.content.is-progressing > .stacked-layer{
	>*:not(.stacked-layer) {
		opacity: 0.3;
	}
}
body.login{
	&.is-progressing{
		background: lighten($brand-color-1,10%);
		&:before{
			background-color: darken($brand-color-1,20%);
			opacity:50%;
		}
	}
}


@keyframes is-progressing-animation {
	0%{
		left:-20%;
	}
	100% {
		left: 100%;
	}

}


.page-heading {
    align-items: center;
    margin-bottom: 2rem;

	h1{
		margin-top:1rem;
	}

    @include mq($min-width: map-get($breakpoints, m)) {
        display: flex;
        justify-content: space-between;
        h1 {
            margin-bottom: 0;
			margin-top:0;
        }
		.opc-button-label-small {
			display: none;
		}
		.break {
			display:none !important;
		}

		> div {
			// margin-right: -0.5rem;
			// margin-top: -0.5rem !important;

			> *:not(.break) {
				margin-top: 0.5rem  !important;
			}
		}
	}

	@include mq($max-width: map-get($breakpoints, m)) {
		> div {
			margin-right: -0.5rem;

			> * {
				margin-right: 0.5rem;
			}
		}
	}

	div{
		display: flex;
		flex-wrap: wrap;
		overflow-x: auto;
		position:relative;
		justify-content: flex-end;

		> * {
			flex: 0 0 auto;
			margin-left: 0.5rem;
			&:nth-last-child(n+1):nth-last-child(-n+1):first-child, 
			&:nth-last-child(n+1):nth-last-child(-n+1):first-child ~ * {
				flex:unset;
			}
			// &:last-child{
			// 	margin-right:0;
			// }
		}

		&:empty{
			display:none;
		}

		-webkit-overflow-scrolling: touch;
		/*height: auto;
		overflow-x: auto;
		white-space: nowrap;*/
		&::-webkit-scrollbar {
			display: none;
		}


		@include mq($max-width: map-get($breakpoints, m)) {

			.opc-button{
				flex-basis: 100%;
				width: 100%;
				margin-top:0.6em;
			}
			.opc-icon-button{
				flex-basis: 50px !important;
				width: 50px;
				margin-top:0.6em;
				flex-grow: 1;

				.opc-button-label-large {
					display: none;
				}
				&.opc-dropdown-button {
					height: 40px;
					margin-top:0em;
				}
			}
			.opc-dropdown-button{
				flex-basis: 99%;
				width: 100%;
			}
			.mobile-full-width {
				width: 100%;
			}
		}
	}

	> div > div:not(.opc-dropdown-button) {
		margin-left: 0rem !important;
	}

	@include mq($min-width: map-get($breakpoints, m)) {
		.opc-dropdown-button > button {
			margin-left: 0rem !important;
		}
	}
}

@include mq($min-width: map-get($breakpoints, m)) {
    .page-heading {
        h1 {
            small {
                display: none;
            }
        }
    }
}

@include mq($max-width: map-get($breakpoints, m)) {

	.break {
		flex-basis: 100% !important;
		height: 0 !important;
		display: block !important;
	}

	.page-heading {
		margin-left: -0.5rem;
		h1 {
			margin-left: 0.5rem;
		}
	}

	.filter-toggle{
		padding: 6px 2rem !important;

		color: rgba(0,0,0,0.8) !important;
		background: rgb(204,204,204) !important;
		border-radius: 3px;

		&:after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.3em solid;
			border-right: 0.3em solid transparent;
			border-bottom: 0;
			border-left: 0.3em solid transparent;

			position: relative;
			float: right;
			margin-top: 10px;
		}
	}
}

figure {
    margin: 0;
    padding: 0;
}

// Bilder
picture img {
    height: auto;
}

// Text
.text-success{
	color:$success-color;
}
.text-error{
	color:$error-color;
}
.text-color{
	color:$info-color;
}
.text-warning{
	color:$warning-color;
}

.pill{
	padding: 0.2rem 0.8rem;
    display: inline-block;
    margin: 1rem;
    border-radius: 5rem;
    position: relative;
    font-weight: bold;
    font-size: inherit;
	background: $brand-color-1;
	color: #efefef;
	&.inverted{
		background: #efefef;
		color: darken($brand-color-1,20%);
	}
}


//Mapbox
.mapboxgl-popup {
    z-index: 1;
}

span.contact-initials {
    vertical-align: super;
}

.flex-nowrap {
	flex-wrap: nowrap;

	.opc-icon-button {
		margin-right: 0.5rem !important;
	}
	.opc-icon-button:last-child {
		margin-right: 0rem !important;
	}
}