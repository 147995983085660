/*
	Verkaufsdokumente DocStateCd Icons
*/
[data-op-item-marker-value^="Opportunity-Opportunity_OpportLevelNo"],[data-op-item-marker-value^="OpportunityUser-Opportunity_OpportLevelNo"]{
    &:before {
		content: "\f0c8";
		font-weight: 900;
    }
}

// Filter ausblenden (Nur als Init verwendet)
.opc-filter-set-opportunity .filter-set-list-item{
	&:nth-child(8),&:nth-child(9),&:nth-child(10),&:nth-child(11){
		display:none;
	}
}

.stacked-layer-body .opc-button-wrapper {
	button,a {
		margin-left: 0px !important;
	}
}