/*###############################################

@Title: Navigation
@Description:
This file contains the navigation styles

###############################################*/

$_opc-navigation-padding-level-1: 1.5rem 2rem !default;
$_opc-navigation-padding-level-2: 0.5rem 1rem !default;
$_opc-navigation-padding-level-3: 0.5rem 1rem !default;
$_opc-navigation-level-2-top: 110% !default;
$_opc-navigation-bg: #000 !default;
$_opc-navigation-bg-hover: rgba(#000, 0.4) !default;
$_opc-navigation-bg-active: $brand-color-1 !default;
$_opc-navigation-color: #fff !default;
$_opc-navigation-color-hover: #fff !default;
$_opc-navigation-color-active: #fff !default;
$_opc-navigation-submenu-bg : #333 !default;
$_opc-navigation-submenu-bg-hover: rgba(#000, 0.2) !default;
$_opc-navigation-submenu-bg-active: rgba(#000, 0.2) !default;
$_opc-navigation-megamenu-bg : #333 !default;
$_opc-navigation-megamenu-headline-color: rgba(#fff, 1) !default;


.opc-navigation {
    @include clearfix();
    /* ---------------------------------------------------- */
    // global nav reset styles
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        @include clearfix();
        li {
            a {
                display: block;
                text-decoration: none;
                color: #000;
                &:hover {
                    color: lighten(#000, 40%);
                }
            }
        }
    }
    /* ---------------------------------------------------- */
    // global nav level 1
    .navigation-level-1 {
        >li {
            >a {}
            &:hover {
                // style a tag of hovered element
                >a {}
                // show nav level 2 on hover if available
                >.navigation-level-2 {}
            }
        }
    }
    /* ---------------------------------------------------- */
    // global nav level 2
    .navigation-level-2 {
        >li {
            >a {
                white-space: nowrap;
            }
            &:hover {
                >a {}
            }
        }
    }
    .navigation-level-3 {}
    /*
 
     horizontal menu (default)
     ---------------------------------------------------- */
    &[data-op-navtype="default-click"],
    &[data-op-navtype="default-hover"] {
        // nav level 1
        .navigation-level-1 {
            >li {
                float: left;
                position: relative;
                > a {
                    padding: $_opc-navigation-padding-level-1;
                    &:hover {
                        color: #fff;
                        background-color: $_opc-navigation-submenu-bg-hover;
                    }
                }
                &.is-active,
                &.is-active-parent {
                    >a {
                        color: $_opc-navigation-color-active;
                        background: $_opc-navigation-bg-active;
                        &:hover {
                            color: $_opc-navigation-color-active;
                            background: darken($_opc-navigation-bg-active, 10%);
                        }
                    }
                }
                &.is-open {
                    // style a tag of hovered element
                    >a {
                        background: $_opc-navigation-bg-hover;
                    } // show nav level 2 on hover if available
                    >.navigation-level-2 {
                        display: block;
                        visibility: visible;
                        opacity: 1;
                        transform: translate(0, 0.5rem) scale(1);
                        transition-delay: 0.1s;
                    }
                }
            }
        } // nav level 2
        .navigation-level-2 {
            position: absolute;
            left: 0;
            top: 100%;
            background: $_opc-navigation-submenu-bg;
            visibility: hidden;
            opacity: 0;
            transform: translate(0, 0) scale(0.95);
            transition: all 0.2s ease-in-out;
            &::before {
                content: '';
                position: absolute;
                top: -0.5rem;
                height: 0.5rem;
                width: 100%;
                background: transparent;
            }
            >li {
                >a {
                    min-width: 16rem;
                    padding: 1rem 2rem;
                    white-space: nowrap;
                }
                &:hover {
                    >a {
                        background: rgba(#000, 0.1);
                        color: #fff;
                    }
                }
                &.is-active,
                &.is-active-parent {
                    > a {
                        color: $brand-color-1;
                    }
                }
            }
        }
        .navigation-level-3 {
            display: none;
        }
    }
    &[data-op-navtype="default-hover"] {
        // nav level 1
        .navigation-level-1 {
            >li {
                &:hover {
                    // style a tag of hovered element
                    >a {
                        background: $_opc-navigation-bg-hover;
                        
                    } // show nav level 2 on hover if available
                    >.navigation-level-2 {
                        display: block;
                        visibility: visible;
                        opacity: 1;
                        transform: translate(0, 0.5rem) scale(1);
                        transition-delay: 0.1s;
                    }
                }
            }
        }
    }
    /*
 
     Mega Menü
     ---------------------------------------------------- */
    &[data-op-navtype="megamenu-click"],
    &[data-op-navtype="megamenu-hover"] {
        // nav level 1
        .navigation-level-1 {
            >li {
                float: left;
                > a {
                    padding: $_opc-navigation-padding-level-1;
                    &:hover {
                        color: #fff;
                        background-color: $_opc-navigation-submenu-bg-hover;
                    }
                }
                &.is-active,
                &.is-active-parent {
                    >a {
                        color: $_opc-navigation-color-active;
                        background: $_opc-navigation-bg-active !important;
                        &:hover {
                            color: $_opc-navigation-color-active;
                            background: darken($_opc-navigation-bg-active, 10%) !important;
                        }
                    }
                }
                &.is-open {
                    // style a tag of hovered element
                    >a {
                        background: $_opc-navigation-bg-hover;
                    } // show nav level 2 on hover if available
                    >.navigation-level-2 {
                        //display: flex;
                        display: block;
                        visibility: visible;
                        opacity: 1;
                        transform: translate(0, 0.5rem) scale(1);
                        transition-delay: 0.1s;
                    }
                }
            }
        } // nav level 2
        .navigation-level-2 {
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1000;
            top: 100%;
            background: #333;
            background: $_opc-navigation-megamenu-bg;
            visibility: hidden;
            opacity: 0;
            transform: translate(0, 0) scale(0.95);
            transition: all 0.2s ease-in-out;
            column-count: 4;
            padding-top: 2rem;
            padding-bottom: 2rem;
            >li {
                display: inline-block;
                page-break-inside: avoid;
                break-inside: avoid-column;
                margin-bottom: 2rem;
                >a {
                    min-width: 16rem;
                    padding: 1rem 2rem;
                    white-space: nowrap;
                    color: $_opc-navigation-megamenu-headline-color;
                    font-weight: 600;
                }
                &:hover {
                    >a {
                        background: rgba(#000, 0.1);
                        background: transparent;
                    }
                }
            }
        }
        .navigation-level-3 {
            display: block;
            >li {
                >a {
                    padding: 0.5rem 2rem;
                    opacity: 0.8;
                    color: $_opc-navigation-megamenu-headline-color;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
    &[data-op-navtype="megamenu-hover"] {
        // nav level 1
        .navigation-level-1 {
            >li {
                &:hover {
                    // style a tag of hovered element
                    >a {
                        background: $_opc-navigation-bg-hover;
                    } // show nav level 2 on hover if available
                    >.navigation-level-2 {
                        //display: flex;
                        display: block;
                        visibility: visible;
                        opacity: 1;
                        transform: translate(0, 0.5rem) scale(1);
                        transition-delay: 0.1s;
                    }
                }
            }
        }
    }
    /*
 
     aside menu (default)
     ---------------------------------------------------- */
    &[data-op-navtype="aside-click"] {
        .navigation-level-1 {
            >li {
                margin-bottom: 0.5rem;
                >a {
                    padding: 1rem;
                }
                &.is-open {
                    >a {
                        font-weight: bold;
                        color: $brand-color-1;
                    }
                    >.navigation-level-2 {
                        display: block;
                    }
                }
            }
        }
        .navigation-level-2 {
            display: none;
            >li {
                border-bottom: 1px solid rgba(#fff, 0.2);
                >a {
                    padding: 0.5rem 1rem 0.5rem 2rem;
                }
                &.is-open {
                    >a {
                        font-weight: bold;
                        color: $brand-color-1;
                    }
                    >.navigation-level-3 {
                        display: block;
                    }
                }
            }
        }
        .navigation-level-3 {
            display: none;
            >li {
                >a {
                    padding: 0.5rem 1rem;
                }
                &.is-open {
                    >a {
                        font-weight: bold;
                        color: $brand-color-1;
                    }
                    >.navigation-level-4 {
                        display: block;
                    }
                }
            }
        }
        .navigation-level-4 {
            display: none;
            >li {
                >a {
                    padding: 0.5rem 1rem;
                }
                &.is-open {
                    >a {
                        font-weight: bold;
                        color: $brand-color-1;
                    }
                    >.navigation-level-5 {
                        display: block;
                    }
                }
            }
        }
    }
    /*
 
     Simple Menü
     ---------------------------------------------------- */
    &[data-op-navtype="simple"] {
        a {
            &::before {
                margin-right: 0.5rem;
                content: '';
                width: 1em;
                height: 1em;
                display: inline-block;
                background-image: inline-svg($op-icon-angle-right-svg, '#000');
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                opacity: 0.6;
            }
            &:hover {
                &::before {
                    opacity: 1;
                }
            }
        } // nav level 1
        .navigation-level-1 {
            >li {
                >a {
                    padding: 0.5rem 0;
                }
                &:hover {
                    // style a tag of hovered element
                    >a {}
                    // show nav level 2 on hover if available
                    >.navigation-level-2 {}
                }
            }
        } // nav level 2
        .navigation-level-2 {
            >li {
                >a {
                    white-space: nowrap;
                    padding: 0.5rem 1rem 0.5rem 2rem;
                }
                &:hover {
                    >a {
                        background: rgba(#000, 0.1);
                    }
                }
            }
        }
        .navigation-level-3 {}
    }

    /*
 
     new default menu (default)
     ---------------------------------------------------- */
     &[data-op-navtype="default"]{


        &[data-op-layout="arrow"] {

            .navigation-toggler {
                width: 2.6rem;
                height: 2.6rem;
                
                position: absolute;
                right: 0.5rem;
                top: 50%;
                margin-top: -1.3rem;
                display: none;
                background-image: inline-svg($op-icon-angle-down-svg, '#fff');
                background-position: center;
                background-size: 14px;
                background-repeat: no-repeat;
                cursor: pointer;
                &:hover {
                    background-color: rgba(#000, 0.2);
                }
                
                &::before {
                    content: '';
                    display: block;
                    width: 1px;
                    background: rgba(#fff, 0.4);
                    position: absolute;
                    left: -0.4rem;
                    top: 50%;
                    height: 1.6rem;
                    margin-top: -0.8rem;
                    
                }


            }

            .navigation-label {
                display: none;
            }
            .navigation-parent-label {
                display: none;
            }


            // nav level 1
            .navigation-level-1 {
                display: flex;
                justify-content: flex-start;
                >li {
                    
                    position: relative;
                    > a{
                        padding: $_opc-navigation-padding-level-1;
                        color: $_opc-navigation-color;
                        white-space: nowrap;

                        &:hover {
                            color: #fff;
                            background-color: $_opc-navigation-submenu-bg-hover;
                        }
                    }

                    &.has-children {
                        > a {
                            padding-right: 4rem;
                        }
                        .navigation-toggler {
                            display: block;
                            &::before  {display:none;}
                        }
                    }

                    &.is-active,
                    &.is-active-parent {
                        > a,
                        > .navigation-label {
                            color: $_opc-navigation-color-active;
                            background: $_opc-navigation-bg-active;
                            &:hover {
                                color: $_opc-navigation-color-active;
                                background: darken($_opc-navigation-bg-active, 10%);
                            }
                        }
                    }
                    &.is-open {
                        // style a tag of hovered element
                        > a {
                            background: $_opc-navigation-bg-hover;
                        } // show nav level 2 on hover if available
                        >.navigation-level-2 {
                            display: block;
                            visibility: visible;
                            opacity: 1;
                            transform: translate(0, 0.5rem) scale(1);
                            transition-delay: 0.1s;
                        }
                    }
                }
            } // nav level 2
            .navigation-level-2 {
                position: absolute;
                left: 0;
                top: 100%;
                background: $_opc-navigation-submenu-bg;
                visibility: hidden;
                opacity: 0;
                transform: translate(0, 0) scale(0.95);
                transition: all 0.2s ease-in-out;

                &::before {
                    content: '';
                    position: absolute;
                    top: -0.5rem;
                    height: 0.5rem;
                    width: 100%;
                    background: transparent;
                }

                .navigation-toggler {
                    background-image: inline-svg($op-icon-angle-right-svg, '#fff');
                }

                li {
                    > a {
                        min-width: 16rem;
                        padding: 1rem 2rem;
                        white-space: nowrap;
                    }
                    &:hover {
                        >a {
                            background: rgba(#000, 0.1);
                            color: #fff;
                        }
                    }
                    &.is-active,
                    &.is-active-parent {
                        > a {
                            color: $brand-color-1;
                        }
                    }

                    &.has-children {
                        position: relative;
                        > a {
                          padding-right: 5rem;  
                        }
                    }
                    &.is-open {
                        // style a tag of hovered element
                        position: relative;
                        > a {
                            background: $_opc-navigation-bg-hover;
                        } 
                        // show other nav levels on the right side of the parent, when available
                        > .navigation-level-3,
                        > .navigation-level-4,
                        > .navigation-level-5 {
                            display: block;
                            visibility: visible;
                            opacity: 1;
                            position: absolute;
                            left: 100%;
                            top: 0;
                        }
                    }
                }
            }
            .navigation-level-3,
            .navigation-level-4,
            .navigation-level-5 {
                display: none;
                background: $_opc-navigation-submenu-bg;

            }

        }

        &[data-op-layout="click"] {

            .navigation-toggler {
                display: none;
            }
            .navigation-label {
                display:none;
            }

            .has-children {
                > a { display:none; }
                > .navigation-label {
                    display: block;
                    cursor: pointer;
                }
            }

            .navigation-parent-label {
                display: block;
                font-size: 0.6em;
                text-transform: uppercase;
                opacity: 0.6;
            }


            // nav level 1
            .navigation-level-1 {
                display: flex;
                justify-content: flex-start;
                >li {
                    
                    position: relative;
                    > a,
                    > .navigation-label {
                        padding: $_opc-navigation-padding-level-1;
                        color: $_opc-navigation-color;
                        white-space: nowrap;

                        &:hover {
                            color: #fff;
                            background-color: $_opc-navigation-submenu-bg-hover;
                        }
                    }

                    &.has-children {
                        > a {
                            padding-right: 4rem;
                        }
                    }

                    &.is-active,
                    &.is-active-parent {
                        > a,
                        > .navigation-label {
                            color: $_opc-navigation-color-active;
                            background: $_opc-navigation-bg-active;
                            &:hover {
                                color: $_opc-navigation-color-active;
                                background: darken($_opc-navigation-bg-active, 10%);
                            }
                        }
                    }
                    &.is-open {
                        // style a tag of hovered element
                        > a,
                        > .navigation-label {
                            background: $_opc-navigation-bg-hover;
                        } // show nav level 2 on hover if available
                        >.navigation-level-2 {
                            display: block;
                            visibility: visible;
                            opacity: 1;
                            transform: translate(0, 0.5rem) scale(1);
                            transition-delay: 0.1s;
                        }
                    }
                }
            } // nav level 2
            .navigation-level-2 {
                position: absolute;
                left: 0;
                top: 100%;
                background: $_opc-navigation-submenu-bg;
                visibility: hidden;
                opacity: 0;
                transform: translate(0, 0) scale(0.95);
                transition: all 0.2s ease-in-out;

                &::before {
                    content: '';
                    position: absolute;
                    top: -0.5rem;
                    height: 0.5rem;
                    width: 100%;
                    background: transparent;
                }

                li {
                    > a,
                    > .navigation-label {
                        min-width: 16rem;
                        padding: 1rem 2rem;
                        white-space: nowrap;
                        color: $_opc-navigation-color;
                    }
                    &:hover {
                        > a,
                        > .navigation-label {
                            background: rgba(#000, 0.1);
                            color: #fff;
                        }
                    }
                    &.is-active,
                    &.is-active-parent {
                        > a,
                        > .navigation-label {
                            color: $brand-color-1;
                        }
                    }

                    &.has-children {
                        position: relative;
                    }
                    &.is-open {
                        // style a tag of hovered element
                        position: relative;
                        > a,
                        > .navigation-label {
                            background: $_opc-navigation-bg-hover;
                        } 
                        // show other nav levels on the right side of the parent, when available
                        > .navigation-level-3,
                        > .navigation-level-4,
                        > .navigation-level-5 {
                            display: block;
                            visibility: visible;
                            opacity: 1;
                            position: absolute;
                            left: 100%;
                            top: 0;
                        }
                    }
                }
            }
            .navigation-level-3,
            .navigation-level-4,
            .navigation-level-5 {
                display: none;
                background: $_opc-navigation-submenu-bg;

            }

        }
         
     }

}