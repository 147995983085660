/*###############################################

@Title: Filter
@Description:
This file contains the filter styles

###############################################*/
.select-list li label::before {
	margin-right: 0.5rem;
	min-width: 1.2rem;
}

.opc-filter-set {
	margin-bottom: 0rem;

	.filter-set-list {
		.filter-set-list-item {
			&[data-op-style="fullwidth"] {
				width: 100%;
				max-width: 100%;
				flex-basis: 100%;
			}

			.filteritemamount {
				position: absolute;
				right: 1rem;
				background:#fff;
				padding:0.5rem 0;
			}

			.filter-body {
				.filter-body-inner {
					position: relative;
				}

				.filter-body-reset {
					padding: 0;

					a {
						display: block;
						color: black;
						padding: 0.5rem 2rem;
					}
				}

				label {
					position: relative;
					padding: 1rem 4.5rem 1rem 1rem;
					word-break: break-word;
					background: #ffffff;
				}

				.item-marker {
					display: block;
					position: relative;
					margin-right: 0.5rem;

					&:before {
						font-family: 'Font Awesome 6 Pro';
						position: absolute;
						left: 0;
						top: -0.2em;
						font-size: 1.2em;
					}
				}
			}
		}
	}

	.filter-set-selection {
		margin-bottom: 1rem;

		@include mq($min-width: map-get($breakpoints, m)) {
			margin-bottom: 0rem;
		}

		.filter-reset {
			opacity: 1;
			@extend .opc-button;
			@extend .opc-button[data-op-size="small"];
			@extend .opc-button[data-op-style="secondary-lined"];
			color: rgba(0, 0, 0, 0.7);
		}
	}
}

.opc-filter {
	z-index: inherit;
	margin-bottom: 1.8rem;

	&[data-op-style="dropdown"] {
		cursor: pointer;

		.filter-head {
			padding: 0.9rem 2rem;
			height: 4rem;
		}
	}
}

.opc-filter.is-open {
	z-index: 2;
}

.opc-filter[data-op-style="classic"][data-op-type="text"] .filter-body .filter-body-search input {
	height: calc(4rem - 2px);
	line-height: calc(4rem - 2px);
	padding: 0.9rem 2rem;
}


/* Filter */

.filter-tag-amount {
	display: block;
	border-bottom: 1px solid #ccc;
	margin-bottom: 1rem;
	padding-bottom: 1rem;

	@include mq($min-width: map-get($breakpoints, m)) {
		display: flex;
		justify-content: space-between;
		padding-bottom: 0;
		margin-bottom: 0;
		border-bottom: 0 none transparent;
	}

	:only-child {
		margin-left: auto;
	}

	span {
		white-space: nowrap;
	}
}

.filter-tag-list {
	display: inline-block;
	margin-bottom: 0;
}

.filter-tag-list {
	.tag[data-op-style="info"] {
		padding: 1rem 2rem 1rem 2rem;
	}
}

.globalsearch {
	display: flex;
	flex-direction: column;

	.panel {
		order: 2;
		margin-bottom: 1rem;

		&.panel-global {
			order: 1;

			.opc-filter-set {
				margin-bottom: 1em;

				@include mq($max-width: map-get($breakpoints, m)) {
					.filter-set-list {
						display: inherit;
					}
				}
			}
		}
	}

}

// Min-Width fuer Kalender-Filter
.opc-filter.daterangepicker-input .filter-body{
	max-width: 35rem;
}
// Min-Width fuer Range-Filter
.opc-filter[data-op-type="range"][data-op-layouttype1="opc-rng2"] .filter-body{
	min-width: 220px;
}
.opc-filter[data-op-type="range"][data-op-layouttype1="opc-rng2"] .slider-controls .slider-values{
	width: 100%;
	margin-right: 1rem;
}

//Ellipsis fuer Filterbezeichnungen
.opc-filter .filter-head,
.opc-filter[data-op-style="dropdown"] .filter-head {
	white-space: nowrap;
	text-overflow: ellipsis;
	padding-right: 3rem;
	overflow: hidden;
}

.panel-search {
	position: relative;


	.panel-head+.panel-body[data-op-style="clean"] {
		padding-top: 0;
	}

	.panel-body {
		overflow-y: visible;
		overflow-x: visible;

		.opc-filter,
		.opc-item-list {
			margin-bottom: 0;
		}
	}

	form {
		position: relative;

		.opc-button {
			position: absolute;
			right: 0;
			top: 0;
			height: calc(4rem - 2px);
			line-height: calc(4rem - 2px);
			background: transparent !important;
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}

	input {
		width: 100%;
		height: calc(4rem - 2px);
		line-height: calc(4rem - 2px);
		padding: 0.9rem 2rem;
		height: 4rem;
	}
}

// *************
// ECRM-674- Start

.opc-filter-set[data-op-layout="horizontal"] .filter-set-list:not(.filter-set-list-flex) {
	.filter-set-list-item {
		width: auto;
		max-width: unset;
		padding: 0;
		.opc-filter {
			margin-bottom: 0rem;
		}
	}
}

@include mq($min-width: map-get($breakpoints, m)) {
	.opc-filter-set[data-op-layout="horizontal"] .filter-set-list:not(.filter-set-list-flex) {
		display: grid !important;
		.filter-set-list-item {
			width: auto;
			max-width: unset;
			flex-basis: unset;
			.opc-filter {
				margin-bottom: 0rem;
			}
		}
	}
}

$grid-layout-gap: 1.5rem;
$grid-column-count: $_opc-filter-max-column-count;
$grid-item--min-width: $_opc-filter-min-width;

$gap-count: calc(#{$grid-column-count} - 1);
$total-gap-width: calc(#{$gap-count} * #{$grid-layout-gap});
$grid-item--max-width: calc((100% - #{$total-gap-width}) / #{$grid-column-count});

.opc-filter-set[data-op-layout="horizontal"] .filter-set-list:not(.filter-set-list-flex) {
	display: none;
	@include mq($min-width: map-get($breakpoints, m)) {
		display: grid;
	}
	grid-template-columns: repeat(auto-fit, minmax(Max(#{$grid-item--min-width}, #{$grid-item--max-width}), 1fr));
	grid-gap: $grid-layout-gap;

	margin-left: 0;
	margin-right: 0;
	margin-bottom: $grid-layout-gap;

	.opc-form-field{
		margin-bottom:0;
	}
	>div:nth-last-child(n+1):nth-last-child(-n+1):first-child, 
	>div:nth-last-child(n+1):nth-last-child(-n+1):first-child ~ div {
		max-width:45rem;
	}
	&.is-open{
		display: grid;
	}
}


.opc-filter-set[data-op-layout="horizontal"] .filter-set-list.filter-set-list-flex{
	padding:0 1rem;
	flex-direction: column;
}

.opc-filter-set .filter-toggle {
    margin-bottom: 1.5rem;
}
// ECRM-674- End
// *************