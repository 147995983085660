.addr-search-list, .addr-search-history {
    .item-list-head {
        .list-item-column[data-sort-type] {
            opacity: 0.5;
            cursor: pointer;
        }
        .list-item-column.sorted {
            opacity: 1;
        }
    }

}